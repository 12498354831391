<template>
  <div>
    <dv-border-box-13><div id="myChartOne" style="height:410px;"></div></dv-border-box-13>
  </div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  data() {
    return {
		option:null,
		charts:null,
		nodedata:[{
			name :"关键词：中国",
			value: [100,200],//点的坐标
			symbol:	'image://'+require('@/assets/img/keywords.png'),//形状
			label: {
				normal: {
					borderRadius: 2,
					color:"#fff",
					fontSize: 12,//点的标题自豪大小
					position: [-15,60],//点的标题所在位置
				}
			},
			color:"#900000",
			itemStyle: {//点的样式
				color:"#F3F8FE",
				color:"rgba(208,213,221,1)",
				borderRadius:10
			}
		},
		{
			name :"计算机软件著作权登记申请表",
			value: [310,100],//点的坐标
			symbol:	'image://'+require('@/assets/img/file.png'),//形状
			symbolSize: [50,50],//点的大小
			borderRadius: 10,
			label: {
				normal: {
					borderRadius: 10,
					color:"#fff",
					fontSize: 12,//点的标题自豪大小
					position: [-10,70]//点的标题所在位置
				}
			},
			color:"#900000",
			itemStyle: {//点的样式
				color:"#F3F8FE",
				color:"rgba(208,213,221,1)",
				borderRadius:10
			}
		},
		{
			name :"国士之风",
			value: [800,100],//点的坐标
			symbolSize: [30,30],//点的大小
			borderRadius: 10,
			label: {
				normal: {
					borderRadius: 10,
					color:"#fff",
					fontSize: 12,//点的标题自豪大小
					position: [3,44]//点的标题所在位置
				}
			},
			color:"#900000",
			symbol:	'image://'+require('@/assets/img/user.png'),//形状
			itemStyle: {//点的样式
				color:"#F3F8FE",
				color:"rgba(208,213,221,1)",
				borderRadius:10
			}
		},
		{
			name :"天蓝国风",
			value: [500,300],//点的坐标
			symbolSize: [30,30],//点的大小
			borderRadius: 10,
			label: {
				normal: {
					borderRadius: 10,
					color:"#fff",
					fontSize: 12,//点的标题自豪大小
					position: [3,44],//点的标题所在位置
				}
			},
			color:"#900000",
			symbol:	'image://'+require('@/assets/img/user.png'),//形状
			itemStyle: {//点的样式
				color:"#F3F8FE",
				color:"rgba(208,213,221,1)",
				borderRadius:10
			}
		},
		{
			name :"admin",
			value: [1100,200],//点的坐标
			symbol:"roundRect",//形状
			symbolSize: [30,30],//点的大小
			borderRadius: 10,
			label: {
				normal: {
					borderRadius: 10,
					color:"#fff",
					fontSize: 12,//点的标题自豪大小
					position: [3,44]//点的标题所在位置
				}
			},
			color:"#900000",
			symbol:	'image://'+require('@/assets/img/user.png'),//形状
			itemStyle: {//点的样式
				color:"#F3F8FE",
				color:"rgba(208,213,221,1)",
				borderRadius:10
			}
		},
		{
			name :"tianlan",
			value: [800,300],//点的坐标
			symbolSize: [30,30],//点的大小
			borderRadius: 10,
			label: {
				normal: {
					borderRadius: 10,
					color:"#fff",
					fontSize: 12,//点的标题自豪大小
					position: [3,44]//点的标题所在位置
				}
			},
			color:"#900000",
			symbol:	'image://'+require('@/assets/img/user.png'),//形状
			itemStyle: {//点的样式
				color:"#F3F8FE",
				color:"rgba(208,213,221,1)",
				borderRadius:10
			}
		},
		{
			name :"薇薇薇",
			value: [500,100],//点的坐标
			symbolSize: [30,30],//点的大小
			borderRadius: 10,
			label: {
				normal: {
					borderRadius: 10,
					color:"#fff",
					fontSize: 12,//点的标题自豪大小
					position: [0,44],//点的标题所在位置
				}
			},
			color:"#900000",
			symbol:	'image://'+require('@/assets/img/user.png'),//形状
			itemStyle: {//点的样式
				color:"#F3F8FE",
				color:"rgba(208,213,221,1)",
				borderRadius:10
			}
		}
		
		
      ],
      linlist:[{
        coords:[
          [100,200],
          [310,100],
        ]
      },{
        coords:[
			[310,100],
          	[500,300]
          
        ]
      },{
        coords:[
			[500,300],
          [800,300],
          
        ]
      },{
        coords:[
			[500,300],
          [800,100],
          
        ]
      },{
        coords:[
			[800,300],
          [1100,200],
          
        ]
      },{
        coords:[
          [310,100],
		  [500,100],
        ]
      },{
        coords:[
          [500,100],
		  [800,100],
        ]
      }],
	   linlist1:[{
        coords:[
          [100,200],
          [310,100],
        ]
      },{
        coords:[
			[310,100],
          	[500,300]
          
        ]
      },{
        coords:[
			[500,300],
          [800,300],
          
        ]
      },{
        coords:[
			[500,300],
          [800,100],
          
        ]
      },{
        coords:[
			[800,300],
          [1100,200],
          
        ]
      },{
        coords:[
          [310,100],
		  [500,100],
        ]
      },{
        coords:[
          [500,100],
		  [800,100],
        ]
      }]

    };
  },
  methods:{
	init(row){
		this.nodedata[0].name = "关键词："+row.row[1]
		this.nodedata[1].name = row.row[0]
		if(row.rowIndex == 0){
			this.nodedata = this.nodedata.slice(0,2)
			this.linlist = this.linlist1 ;
			this.nodedata.push({
				name :"天蓝国风",
				value: [500,300],//点的坐标
				symbolSize: [30,30],//点的大小
				borderRadius: 10,
				label: {
					normal: {
						borderRadius: 10,
						color:"#fff",
						fontSize: 12,//点的标题自豪大小
						position: [3,44],//点的标题所在位置
					}
				},
				color:"#900000",
				symbol:	'image://'+require('@/assets/img/user.png'),//形状
				itemStyle: {//点的样式
					color:"#F3F8FE",
					color:"rgba(208,213,221,1)",
					borderRadius:10
				}
			},{
			name :"国士之风",
			value: [800,100],//点的坐标
			symbolSize: [30,30],//点的大小
			borderRadius: 10,
			label: {
				normal: {
					borderRadius: 10,
					color:"#fff",
					fontSize: 12,//点的标题自豪大小
					position: [3,44]//点的标题所在位置
				}
			},
			color:"#900000",
			symbol:	'image://'+require('@/assets/img/user.png'),//形状
			itemStyle: {//点的样式
				color:"#F3F8FE",
				color:"rgba(208,213,221,1)",
				borderRadius:10
			}
		},{
			name :"tianlan",
			value: [800,300],//点的坐标
			symbolSize: [30,30],//点的大小
			borderRadius: 10,
			label: {
				normal: {
					borderRadius: 10,
					color:"#fff",
					fontSize: 12,//点的标题自豪大小
					position: [3,44]//点的标题所在位置
				}
			},
			color:"#900000",
			symbol:	'image://'+require('@/assets/img/user.png'),//形状
			itemStyle: {//点的样式
				color:"#F3F8FE",
				color:"rgba(208,213,221,1)",
				borderRadius:10
			}
		},
		this.nodedata[5] = {
			name :"admin",
			value: [1100,200],//点的坐标
			symbol:"roundRect",//形状
			symbolSize: [30,30],//点的大小
			borderRadius: 10,
			label: {
				normal: {
					borderRadius: 10,
					color:"#fff",
					fontSize: 12,//点的标题自豪大小
					position: [3,44]//点的标题所在位置
				}
			},
			color:"#900000",
			symbol:	'image://'+require('@/assets/img/user.png'),//形状
			itemStyle: {//点的样式
				color:"#F3F8FE",
				color:"rgba(208,213,221,1)",
				borderRadius:10
			}
		}, {
			name :"薇薇薇",
			value: [500,100],//点的坐标
			symbolSize: [30,30],//点的大小
			borderRadius: 10,
			label: {
				normal: {
					borderRadius: 10,
					color:"#fff",
					fontSize: 12,//点的标题自豪大小
					position: [0,44],//点的标题所在位置
				}
			},
			color:"#900000",
			symbol:	'image://'+require('@/assets/img/user.png'),//形状
			itemStyle: {//点的样式
				color:"#F3F8FE",
				color:"rgba(208,213,221,1)",
				borderRadius:10
			}
			})
		}else{
			let namea = Math.random();
            //随机生成的数字 0-1之间
            //乘以6之后 0-7之间
            let Nameb = namea * 5
            // 向下取整
            let nameC = Math.floor(Nameb)//向下取整
						let nameD = Math.floor(Nameb)//向下取整
			const nameList = ['天蓝国风','国士之风','tianlan','小苹果','admin','火花']
			const nameListB=['国士之风','天蓝国风','小苹果','火花','admin','tianlan']
			this.nodedata = this.nodedata.slice(0,2)
			this.linlist = this.linlist.slice(0,3)
			this.nodedata.push({
				name :nameList[nameC],
				value: [500,300],//点的坐标
				symbolSize: [30,30],//点的大小
				borderRadius: 10,
				label: {
					normal: {
						borderRadius: 10,
						color:"#fff",
						fontSize: 12,//点的标题自豪大小
						position: [3,44],//点的标题所在位置
					}
				},
				color:"#900000",
				symbol:	'image://'+require('@/assets/img/user.png'),//形状
				itemStyle: {//点的样式
					color:"#F3F8FE",
					color:"rgba(208,213,221,1)",
					borderRadius:10
				}
			},{
			name :nameListB[nameD],
			value: [800,300],//点的坐标
			symbolSize: [30,30],//点的大小
			borderRadius: 10,
			label: {
				normal: {
					borderRadius: 10,
					color:"#fff",
					fontSize: 12,//点的标题自豪大小
					position: [3,44]//点的标题所在位置
				}
			},
			color:"#900000",
			symbol:	'image://'+require('@/assets/img/user.png'),//形状
			itemStyle: {//点的样式
				color:"#F3F8FE",
				color:"rgba(208,213,221,1)",
				borderRadius:10
			}
		})
		}
		this.option.series[0].data = this.linlist;
		this.option.series[1].data = this.linlist;
		this.option.series[2].data = this.nodedata;

		this.charts.clear();
		this.charts.setOption(this.option)
	}
  },

  mounted(){
    this.charts = echarts.init(document.getElementById('myChartOne'));

	this.option =  {
		title: {
			text: "",
			top: "top",
			left: "center",
			textStyle:{
				color:"#f8f8f8",
				fontSize:26
			}
		},
		tooltip: {
			trigger: 'item',
			formatter: function(o) {
				return o.name
			}
		},
		geo: {
			// 地理系坐标组件
			// map: 'MyGum',
			// label: {
			// 	emphasis: {
			// 		show: false
			// 	}
			// },
			// roam: true,
			itemStyle: {
				// normal: {
				// 	color:'rgba(255,255,255,0)',
				// 	areaColor:'rgba(255,255,255,0)',
				// 	borderColor:'rgba(22,22,2,0)'

				// },
				// emphasis: {

				// 	color:'rgba(22,22,2,0)',
				// 	areaColor:'rgba(255,255,255,0)',
				// 	borderColor:'rgba(22,22,2,0)'
				// }
			}
		},
		// 点
		series: [{
			type: 'lines',
			zlevel: 1,
			effect: {
				show: true,
				period: 3,
				trailLength: 0.3,
				color: '#A6C84C',
				symbol: "arrow",
				symbolSize: 8
			},
			lineStyle: {
				normal: {
					color: '#a6c84c',
					width: 0,
					curveness: 0.2
				}
			},
			data: this.linlist
		}, {
			// 三角
			type: 'lines',
			zlevel: 2,
			effect: {
				show: true,
				period: 3,
				trailLength: 0,
				symbol: "arrow",
				symbolSize: 15
			},
			lineStyle: {
				normal: {
					color: '#a6c84c',
					width: 1,
					opacity: 0.4,
					curveness: 0.2
				}
			},
			data: this.linlist
		}, {
			// 涟漪
			type: 'effectScatter',
			coordinateSystem: 'geo',
			zlevel: 2,
			rippleEffect: {
				period: 6,
				scale: 2,
				brushType: 'stroke'
			},
			label: {
				normal: {
					show: true,
					position: 'right',
					formatter: '{b}'
				}
			},
			// symbol:'image://'+require('@/assets/img/keywords.png'),
			symbolSize: '40',
			itemStyle: {
				normal: {
					color: '#a6c84c',
				}
			},
			data: this.nodedata
		}]
	}


	// 使用刚指定的配置项和数据显示图表。
	this.charts.setOption(this.option)
  }
 
};
</script>

<style lang='less' scoped>
.form-content {
  width: 300px;
  padding-bottom: 20px;
}
.box-card {
  border: none;
}
.submit {
  text-align: center;
}

.reset-wraps {
  display: flex;
  margin-top: 88px;
  .reset-lefts {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    img {
      width: 400px;
      // height: 303px;
    }
  }
  .reset-rights {
    h3 {
      margin: 0px;
      padding: 0px;
      position: relative;
      &::before {
        content: '';
        width: 5px;
        height: 20px;
        background: #409EFF;
        position: absolute;
        left: 0px;
        top: 4px;
      }
      padding-left: 15px;
    }
    p {
      width: 80%;
      color: #737B8B;
      font-size: 14px;
      line-height: 22px;
      margin: 20px 0 30px 0;
    }
    .el-form {
      .el-form-item {
        margin-bottom: 30px;
      }
    }
  }
}
</style>