<template>
    <div class="contentOneBox">
        <div class="grid" v-for="(item,index) in warningList" :key="index">
            <dv-border-box-9>
                <span>{{ item.title }}</span>
                <span style="font-size:30px;margin-top:10px">
                    <count-to
                    :start-val="0"
                    :end-val="item.data"
                    :duration="5000"
                    class="fontG"
                /></span>
            </dv-border-box-9>
        </div>
    </div>
</template>
<script>
import CountTo from "vue-count-to";
export default {
    components:{ CountTo },
    data(){
        return {
            warningList:[{
                title:'关键词总数',
                data:26
            },{
                title:'预警总数',
                data:288
            },{
                title:'未处理预警数',
                data:98
            }]
        }
    }
}
</script>

<style lang="less" scoped>
	.contentOneBox{
		height: 110px;
		padding: 10px;
		display: grid;
		grid-template-columns: 5.8fr 5.8fr 5.8fr;
		box-sizing: border-box;
		div.dv-border-box-9{
			color: #06f1ef;
			box-sizing: border-box;
			text-align: center;
			height: 100%;
			::v-deep .border-box-content{
                padding-top:20px;
				display: flex!important;
				flex-direction: column!important;
			}
		}
	}
</style>