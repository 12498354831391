<template>
  <div id="table1">
    <div class="title">
      <i
        class="iconfont icon-exe-sale-primary"
        style="color: aqua; font-size: 15px; margin-right: 5px"
      ></i>
      <span style="font-size: 15px">{{title}}</span
      >
      <dv-decoration-1 style="width:200px; height: 25px;position:absolute;right:10px" />
    </div>
    <dv-scroll-board
     @click="handleClick"
      :config="config"
      ref="scrollBoard"
      style="width: 100%; height: 95%; font-size: 12px"
    />
  </div>
</template>

<script>
export default {
  props:{
    title:{
      type:String,
      default:''
    }, 
    config:{
      type:Object,
      default:[]
    }
  },
  data() {
    return {
    };
  },
  created() {
    // window.addEventListener("resize", () => {
    //   this.$refs["scrollBoard"].updateRows();
    // });
  },
  mounted() {},
  methods: {
    handleClick(row){
      this.$emit("handleClick",row)
    }
  },
};
</script>

<style lang="less" scoped>
::v-deep .dv-scroll-board .rows .ceil {
  font-size: 13px !important;
}
#table1 {
  width: 100%;
  height: 94%;
  padding:0 10px;
  box-sizing: border-box;
  overflow: hidden;
  .title {
    display: flex;
    height: 30px;
    line-height: 30px;
    span {
      color: #c3cbde;
      font-size: 15px;
      margin-right: 10px;
    }
  }
}
</style>
