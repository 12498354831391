<template>
    <div class="contentThreeBox">
        <div class="grid" v-for="(item,index) in warningList" :key="index">
            <dv-border-box-12>
                <Table1 :title='item.title' :config='item.config'></Table1>
            </dv-border-box-12>
        </div>
    </div>
</template>
<script>
import CountTo from "vue-count-to";
import Table1 from '../warningCompontents/table1.vue';
export default {
    components:{ CountTo ,Table1 },
    data(){
        return {
            warningList:[{
                title:'敏感词预警记录Top10',
                data:130,
                config: {
                    header: [
                    "文件名",
                    "敏感词",
                    "预警时间"
                    ],
                    data: [
                    [
                        "可信电子文件管理平台_使用手册",
                        "文档",
                        "2023-03-27 17:46:56"
                    ],
                    [
                        "健康",
                        "文档,测试,中共",
                        "2023-03-27 14:46:42"
                    ],
                    [
                        "协同共享泰山版开发手册",
                        "文档,中共",
                        "2023-03-27 14:35:04"
                    ],
                    [
                        "慧正流程中台技术方案V8.1",
                        "测试",
                        "2023-03-27 14:32:46"
                    ],
                    [
                        "计算机软件著作权登记申请表》_报告管理撒打算打打按时非多水电费水电费水电费水电费水电费",
                        "中国",
                        "2023-03-24 18:02:22"
                    ],
                    [
                        "计算机软件著作权登记申请表》_报告管理撒打算打打按时非多水电费水电费水电费水电费水电费",
                        "中国",
                        "2023-03-24 18:02:22"
                    ],
                    [
                        "协同共享泰山版",
                        "中共,测试",
                        "2023-03-24 17:36:10"
                    ],
                    [
                        "主索引接口文档1.0.1(可信一体机)",
                        "中国",
                        "2023-03-23 15:15:44"
                    ],
                    [
                        "《计算机软件著作权登记申请表》_报告管理",
                        "测试",
                        "2023-03-23 14:15:26"
                    ],
                    [
                        "0230322",
                        "测试",
                        "2023-03-23 14:13:13"
                    ]
                    ],
                    hoverPause: false, //鼠标悬浮时暂停轮播
                    rowNum: 10, //表格行数
                    headerHeight: 30, //表头高度
                    headerBGC: "#77c7c74f", //表头背景颜色
                    oddRowBGC: "#0f1325", //奇数行背景颜色
                    evenRowBGC: "#171c33", //偶数行背景颜色
                    index: false, //是否开启序号
                    indexHeader: "序号", //序号名称
                    waitTime: 10000, //翻页时间
                    carousel: "page", //滚动类型page为整页，默认为单条
                    align: [
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    ], //对齐方式Array[x,x,x,x]
                },
            },{
                title:'访问量预警记录TOP10',
                data:34,
                config: {
                    header: [
                    "共享名称",
                    "访问超过阈值(100)",
                    "文件类型"
                    ],
                    data: [
                    [
                        "协同共享泰山版开发手册",
                        "439次",
                        "pdf"
                    ],
                    [
                        "GBT_33190-2016_电子文件存储与交换格式版式文档",
                        "353次",
                        "wps"
                    ],
                    [
                        "企业填报问题",
                        "332次",
                        "doc"
                    ],
                    [
                        "慧正流程中台技术方案V8.1",
                        "328次",
                        "ofd"
                    ],
                    [
                        "劳模与职工健康管理系统-招标需求",
                        "228次",
                        "pdf"
                    ],
                    [
                        "计算机软件著作权登记申请表》_报告管理撒打算打打按时非多水电费水电费水电费水电费水电费",
                        "193次",
                        "pdf"
                    ],
                    [
                        "阅读器服务版对接文档",
                        "184次",
                        "doc"
                    ],
                    [
                        "主索引接口文档1.0.1(可信一体机)",
                        "130次",
                        "ofd"
                    ],
                    [
                        " 回单示例文件",
                        "113次",
                        "pdf"
                    ],
                    [
                        "转换套件产品使用说明_linux",
                        "110次",
                        "png"
                    ]
                    ],
                    hoverPause: false, //鼠标悬浮时暂停轮播
                    rowNum: 10, //表格行数
                    headerHeight: 30, //表头高度
                    headerBGC: "#77c7c74f", //表头背景颜色
                    oddRowBGC: "#0f1325", //奇数行背景颜色
                    evenRowBGC: "#171c33", //偶数行背景颜色
                    index: false, //是否开启序号
                    indexHeader: "序号", //序号名称
                    waitTime: 10000, //翻页时间
                    carousel: "page", //滚动类型page为整页，默认为单条
                    align: [
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    ], //对齐方式Array[x,x,x,x]
                },
            },{
                title:'下载预警记录TOP10',
                data:12,
                  config: {
                    header: [
                    "文件名称",
                    "下载次数超过阈值(100)",
                    "文件所有者"
                    ],
                    data: [
                    [
                        "回单示例文件",
                        "192次",
                        "www1"
                    ],
                    [
                        "泰山版(1)",
                        "177次",
                        "国士之风"
                    ],
                    [
                        "鉴定文书-数字版",
                        "153次",
                        "天蓝国风"
                    ],
                    [
                        "长城文链-隐写溯源工具",
                        "153次",
                        "国士之风"
                    ],
                    [
                        "阅读器服务版对接文档",
                        "129次",
                        "天蓝国风"
                    ],
                    [
                        "思维导图",
                        "121次",
                        "国士之风"
                    ],
                    [
                        "企业填报问题",
                        "116次",
                        "tianlan"
                    ],
                    [
                        "数字化体检报告",
                        "116次",
                        "15612005398"
                    ],
                    [
                        " 友虹电子文档链控平台V8-2",
                        "107次",
                        "www1"
                    ],
                    [
                        "XXjob部署及集成",
                        "101次",
                        "宋HHH"
                    ]
                    ],
                    hoverPause: false, //鼠标悬浮时暂停轮播
                    rowNum: 10, //表格行数
                    headerHeight: 30, //表头高度
                    headerBGC: "#77c7c74f", //表头背景颜色
                    oddRowBGC: "#0f1325", //奇数行背景颜色
                    evenRowBGC: "#171c33", //偶数行背景颜色
                    index: false, //是否开启序号
                    indexHeader: "序号", //序号名称
                    waitTime: 10000, //翻页时间
                    carousel: "page", //滚动类型page为整页，默认为单条
                    align: [
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    ], //对齐方式Array[x,x,x,x]
                },
            },{
                title:'打印预警记录TOP10',
                data:12,
                  config: {
                    header: [
                    "文件名",
                    "打印次数超过阈值(100)",
                    "文件所有者"
                    ],
                    data: [
                    [
                        "采购订单",
                        "288次",
                        "天蓝"
                    ],
                    [
                        "阅读器功能对比表",
                        "276次",
                        "tianlan"
                    ],
                    [
                        "协同共享泰山版开发手册",
                        "243次",
                        "薇薇薇"
                    ],
                    [
                        "慧正流程中台技术方案V8.1",
                        "239次",
                        "admin"
                    ],
                    [
                        "可信文件管理平台-白皮书jg",
                        "229次",
                        "admin"
                    ],
                    [
                        "协同共享泰山版",
                        "176次",
                        "啦啦啦啦"
                    ],
                    [
                        "certificate",
                        "153次",
                        "国士之风"
                    ],
                    [
                        "sign_sign_sign_sign_百度分布式数据实践",
                        "149次",
                        "1383737390"
                    ],
                    [
                        "凭证",
                        "149次",
                        "1638920098"
                    ],
                    [
                        "服务平台测试环境地址",
                        "147次",
                        "1638920098"
                    ],
                    ],
                    hoverPause: false, //鼠标悬浮时暂停轮播
                    rowNum: 10, //表格行数
                    headerHeight: 30, //表头高度
                    headerBGC: "#77c7c74f", //表头背景颜色
                    oddRowBGC: "#0f1325", //奇数行背景颜色
                    evenRowBGC: "#171c33", //偶数行背景颜色
                    index: false, //是否开启序号
                    indexHeader: "序号", //序号名称
                    waitTime: 10000, //翻页时间
                    carousel: "page", //滚动类型page为整页，默认为单条
                    align: [
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    ], //对齐方式Array[x,x,x,x]
                },
            }]
        }
    }
}
</script>

<style lang="less" scoped>
	.contentThreeBox{
		height: 380px;
		padding: 10px;
		display: grid;
		grid-template-columns: 4.5fr 4.5fr 4.5fr 4.5fr;
		box-sizing: border-box;
		div.dv-border-box-12{
			color: #06f1ef;
			box-sizing: border-box;
			text-align: center;
			height: 380px;
			::v-deep .border-box-content{
				display: flex!important;
                height: 380px;
				flex-direction: column!important;
				justify-content: space-evenly!important;
			}
		}
	}
</style>