<template>
  <div class="topTitleBox">
      <div class="dateTimeBox">
        <div class="dateTimeBox-after"></div>
        <span class="dateText">
          <dv-decoration-7 style="height: 30px"
            >{{ dateYear }} {{ dateWeek }} {{ dateDay }}</dv-decoration-7
          ></span>
      </div>
      <dv-decoration-10 style="width: 33.33%; height: 10px; margin-top: 20px" />
      <div class="leftDecorate">
        <p v-for="(item,index) in navList" :key="index" @click="jumpRouter(item.path)"><dv-decoration-7   class="menu" style="height: 30px"
              ><span >{{ item.title }}</span></dv-decoration-7></p>
      </div>
      <dv-decoration-8
        :color="['#568aea', '#000000']"
        style="width: 10%; height: 50px; margin-top: 25px"
      />
      <div class="title">
        <dv-decoration-5
          :dur="2"
          :color="['#568aea', '#568aea']"
          style="width: 100%; height: 30px"
        /><span>长城文链数据可视化平台</span>
        <dv-decoration-6
          class="title-bototm"
          :reverse="true"
          :color="['#50e3c2', '#67a1e5']"
          style="width: 250px; height: 10px"
        />
      </div>
      <dv-decoration-8
        :reverse="true"
        :color="['#568aea', '#000000']"
        style="width: 10%; height: 50px; margin-top: 20px"
      />
      <dv-decoration-10
        style="width: 33.33%;height: 10px;margin-top: 15px; transform: rotateY(180deg);" />
    </div>
</template>
<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      dateDay: null, //时间
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      navList:[{title:'首页',path:'/managerLange'},{title:'统计',path:'/managerStatistics'},{title:'告警',path:'/earlyWarning'}]
    };
  },
  created() {},
  mounted() {
    this.timeFn();
  },
  methods: {
    jumpRouter(path){
      this.$router.push(path)
    },
    timeFn() {
      //获取时间
      setInterval(() => {
        this.dateDay = this.formatTimes(new Date(), "HH: mm: ss");
        this.dateYear = this.formatTimes(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    formatTimes(time, fmt) {
      if (!time) return "";
      else {
        const date = new Date(time);
        const o = {
          "M+": date.getMonth() + 1,
          "d+": date.getDate(),
          "H+": date.getHours(),
          "m+": date.getMinutes(),
          "s+": date.getSeconds(),
          "q+": Math.floor((date.getMonth() + 3) / 3),
          S: date.getMilliseconds(),
        };
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (date.getFullYear() + "").substr(4 - RegExp.$1.length)
          );
        for (const k in o) {
          if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length === 1
                ? o[k]
                : ("00" + o[k]).substr(("" + o[k]).length)
            );
          }
        }
        return fmt;
      }
    },
  },
};
</script>
<style lang="less" scoped>

.topTitleBox {
    width: 100%;
    height: 80px;
    display: flex;
    position: relative;
    .leftDecorate {
      position: absolute;
      // left: 7%;
      top: 32px;
      width: 31%;
      padding-right: 50px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      color: #fff;
      p {
        margin: 0;
        padding: 0;
        .menu {
          cursor: pointer;
          span {
            padding: 0 5px;
            color: #06f1ef;
            background-color: #0f1325;
          }
        }
      }
    }
    .dateTimeBox {
      position: absolute;
      right: 7%;
      top: 27px;
      color: #06f1ef;
      font-size: 20px;
      background-color: rgb(15, 19, 37);
      width: 20.33%;
      height: 50px;
      text-align: center;
      line-height: 50px;
      cursor: pointer;
      -webkit-transform: skewX(-45deg);
      transform: skewX(-45deg);
      .dateText {
        display: inline-block;
        transform: skewX(45deg);
      }
      .dateTimeBox-after {
        position: absolute;
        right: -25px;
        top: 0;
        height: 50px;
        width: 50px;
        background-color: rgb(15, 19, 37);
        -webkit-transform: skewX(45deg);
        transform: skewX(45deg);
      }
    }
    .title {
      width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      font-size: 25px;
      color: white;
    }
  }
</style>
