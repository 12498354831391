<template>
    <div class="contentTwoBox">
        <dv-border-box-13>
            <!-- <div class="searchBox">
                <span>今天</span>
                <span>昨天</span>
                <span>本周</span>
                <span>本月</span>
                <span>本年</span>
                <span>全部</span>
            </div> -->
            <div :id="id" style="height:300px;width:100%"></div>
        </dv-border-box-13>
        <wordListVue ref='wordListVue'></wordListVue>
    </div>
</template>
<script>
import * as echarts from 'echarts'
import wordListVue from './wordList.vue'
export default {
    components:{wordListVue},
    props:{
        id:{
            type:String,
            default:'myCharts'
        }
    },
    data(){
        return {
            formdata:{}
        }
    },
    mounted(){
        var mCharts = echarts.init(document.getElementById(this.id))
        var xDataArr = ['2022/03/15', '2022/03/16', '2022/03/17', '2022/03/18', '2022/03/19', '2022/03/20', '2022/03/21', '2022/03/22', '2022/03/23', '2022/03/24', '2022/03/25', '2022/03/26', '2022/03/27', '2022/03/28', '2022/03/29', '2022/03/30']
        var yDataArr1 = [2, 3, 1, 3, 9, 3, 1,2, 2, 1, 4, 9, 3, 0,1, 1,]
        var yDataArr2 = [2, 1, 1, 3, 6, 3, 1,2, 2, 1, 3, 9, 0, 1,2, 4,]
        var yDataArr3 = [3, 3, 5, 2, 9, 3, 3,3, 2, 3, 4, 2, 0, 3, 3, 2, ]
        var yDataArr4 = [0, 9, 2, 4, 1, 0, 1,0, 3, 3, 2, 12, 0, 0, 1,2,10]
        let _this = this;
        var option = {
            title:{
                text:'预警走势图',
                x:"1%",
                y:'4%',
                textStyle:{
                    fontSize:15,
                    color:'#06f1ef'
                }
            },
            tooltip: {
                show: true,
                trigger: "item",
                triggerOn: "click",
                formatter: function (params) {
                    _this.$refs['wordListVue'].show(params.value,params.name);
                }
            },
            legend: {
                data: ["文档", "中共", "中国","测试"],
                top: "5%",
                 textStyle: {
                    color: "#ffffff",
                },
                tooltip: {
                    show: true,
                },
            },
            grid: {
                left: "2%",
                right: "2%",
                bottom: "10%",
                containLabel: true
            },
            toolbox: {
                feature: {
                saveAsImage: {}
                }
            },
            xAxis: {
                type: 'category',
                data: xDataArr,
                axisLine: {
                    lineStyle: {
                        color: "#06f1ef",
                    },
                },
            },
            yAxis: {
                type: 'value',
                scale: true,
                 axisLine: {
                    lineStyle: {
                        color: "#06f1ef",
                    },
                },
            },
            series: [
                {
                    name:'文档',
                    type: 'line',
                    data: yDataArr1,
                    lineStyle: {
                        width: 3
                    },
                },
                {
                    name:'中共',
                    type: 'line',
                    data: yDataArr2,
                    lineStyle: {
                        width: 3
                    },
                },
                {
                     name:'中国',
                    type: 'line',
                    data: yDataArr3,
                    lineStyle: {
                        width: 3
                    },
                },
                {
                    name:'测试',
                    type: 'line',
                    data: yDataArr4,
                    lineStyle: {
                        width: 3
                    },
                }
            ]
        }
        mCharts.setOption(option)
    }
}
</script>

<style lang="less" scoped>
	.contentTwoBox{
        position: relative;
        clear: both;
        width: 100%;
		height: 300px;
		padding: 10px;
		box-sizing: border-box;
		div.dv-border-box-1{
			color: #06f1ef;
			box-sizing: border-box;
			text-align: center;
			height: 300px;
			::v-deep .border-box-content{
                width: 100%;
                height: 300px;
			}
		}
        .searchBox{
            position: absolute;
            right: 20px;
            top: 25px;
            color: rgba(255, 255, 255, 0.4);
            span{
                margin-right: 10px;
                border: 1px solid rgba(255, 255, 255, 0.4);
                padding: 3px 5px;
                font-size: 13px;
            }
        }
	}
</style>