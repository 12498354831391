<template>
	<div id="main">
		<dv-loading v-if="false">Loading...</dv-loading>
		<StaticHeader></StaticHeader>
		<div class="warning-content">
			<!-- 预警数 -->
			<OneChart></OneChart>
			<TwoChart></TwoChart>
			
			
			<div class="contentBottomBox">
				<!-- <div class="search-box"> <dv-border-box-8>友虹</dv-border-box-8></div> -->
				<div class="contentBottomBox-container" >
					<div>
						<dv-border-box-12>
							<Table1 @handleClick='handleClick' title="最新敏感词预警记录" :config="config"></Table1>
						</dv-border-box-12>
					</div>
					<div>
						<MyChart ref='MyChart'></MyChart>
					</div>
				</div>
			</div>
            
            <ThreeChart></ThreeChart>
		</div>
	</div>
</template>

<script>
import OneChart from './warningCompontents/oneChart.vue';
import TwoChart from './warningCompontents/twoChart.vue';
import ThreeChart from './warningCompontents/threeChart.vue';
import Table1 from './warningCompontents/table1.vue';
import MyChart from './warningCompontents/charts.vue'
import StaticHeader from "./conpontents/staticHeader.vue";
export default {
  name: "Home",
  components: {
    OneChart,
    TwoChart,
    ThreeChart,
    Table1,
    MyChart,
	StaticHeader
  },
  data() {
    return {
		 config: {
                    header: [
                    "文件名",
                    "敏感词",
					"出现次数",
                    "预警时间",
                    "预警状态",
                    ],
                    data: [
						[
                        "计算机软件著作权登记申请表",
                        "中国",
						"29次",
                        "2023-03-24 18:02:22",
                        `<div class="roll-item" style="color:#f56c6c">未处理</div>`
                    ],
                    [
                        "报告管理",
                        "中国",
						"26次",
                        "2023-03-24 18:02:22",
                        `<div class="roll-item" style="color:#f56c6c">未处理</div>`
                    ],
                    [
                        "可信电子文件管理平台_使用手册",
                        "文档",
						"19次",
                        "2023-03-27 17:46:56",
                        `<div class="roll-item" style="color:#f56c6c">未处理</div>`
                    ],
                    [
                        "健康档案文档",
                        "文档,测试,中共",
						"19次",
                        "2023-03-27 14:46:42",
                        `<div class="roll-item" style="color:#67c23a">已处理</div>`
                    ],
                    [
                        "长城链控三方产品对接情况",
                        "文档,中共",
						"15次",
                        "2023-03-27 14:35:04",
                        `<div class="roll-item" style="color:#f56c6c">未处理</div>`
                    ],
					[
                        "《计算机软件著作权登记申请表》_报告管理",
                        "测试",
						"14次",
                        "2023-03-23 14:15:26",
                        `<div class="roll-item" style="color:#67c23a">已处理</div>`
                        
                    ],
                    [
                        "0230322",
                        "测试",
						"7次",
                        "2023-03-23 14:13:13",
                        `<div class="roll-item" style="color:#67c23a">已处理</div>`
                    ],
                    [
                        "慧正流程中台技术方案V8.1",
                        "测试",
						"2次",
                        "2023-03-27 14:32:46",
                        `<div class="roll-item" style="color:#67c23a">已处理</div>`
                    ],
                    
                    [
                        "体检报告转换结果表",
                        "测试",
						"2次",
                        "2023-03-24 17:36:10",
                        `<div class="roll-item" style="color:#67c23a">已处理</div>`
                    ],
                    [
                        "主索引接口文档1.0.1(可信一体机)",
                        "中国",
						"1次",
                        "2023-03-23 15:15:44",
                        `<div class="roll-item" style="color:#67c23a">已处理</div>`
                    ],
                    
            
                    ],
                    hoverPause: false, //鼠标悬浮时暂停轮播
                    rowNum: 10, //表格行数
                    headerHeight: 30, //表头高度
                    headerBGC: "#77c7c74f", //表头背景颜色
                    oddRowBGC: "#0f1325", //奇数行背景颜色
                    evenRowBGC: "#171c33", //偶数行背景颜色
                    index: false, //是否开启序号
                    indexHeader: "序号", //序号名称
                    waitTime: 10000, //翻页时间
                    carousel: "page", //滚动类型page为整页，默认为单条
                    align: [
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    "center",
                    ], //对齐方式Array[x,x,x,x]
                },
    };
  },
  methods:{
    handleClick(row){
        this.$refs['MyChart'].init(row)
    }
  }

};
</script>

<style scoped lang="less">
#main {
  width: 100%;
  height: 1350px;
  min-width: 1850px;
  background: url("../../assets/pageBg.png") no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  ::v-deep .loading-tip {
    font-size: 23px !important;
    color: aqua;
    font-weight: bold;
  }
  .warning-content {
    width: 100%;
    height: calc(1350px - 80px);
    padding-top: 20px;
	.contentBottomBox{
    padding: 0px 10px;
		clear: both;
		.search-box{
      margin: 10px 0;
			/deep/.border-box-content{
				color: #efefef;
				box-sizing: border-box;
				padding: 10px;
				height: 40px;
			}
		}
		.contentBottomBox-container{
			box-sizing: border-box;
			display: grid;
			height: 410px;
			grid-template-columns: 6fr 12fr;
            div.dv-border-box-12{
			color: #06f1ef;
			box-sizing: border-box;
			text-align: center;
			height: 410px;
			::v-deep .border-box-content{
				display: flex!important;
                height: 410px;
				flex-direction: column!important;
				justify-content: space-evenly!important;
			}
		}
		}
	}
    
  }
}
</style>
