<template>
    <el-dialog
      title="预警文档列表"
      :visible.sync="visible"
      :close-on-click-modal="false" 
      width="40%">
      <el-table :data="listData" border size="small" height="400px">
        <el-table-column
            label="文件名">
            <template slot-scope="scope">
              {{scope.row.name}}
            </template>
          </el-table-column>
          <el-table-column
          align="center"
            label="类型">
            <template slot-scope="scope">
              <span>{{scope.row.suffix }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="阈值（分钟/100次）"
            align="center">
            <template>
              <span>100</span>
            </template>
          </el-table-column>
          <el-table-column
            label="预警时间"
            align="center"
            width="160">
            <template slot-scope="scope">
              <span>{{ scope.row.updateDate|date('yyyy-MM-dd') }}</span>
            </template>
          </el-table-column>
            <el-table-column
            align="center"
            label="预警状态">
            <template slot-scope="scope">
              <span>{{ scope.row.state == 0 ?'未处理':'已处理' }}</span>
            </template>
          </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
      </span>
    </el-dialog>
</template>
<script>
export default {
    data(){
        return{
            visible:false,
            listData:[],
            list:['友虹电子文档链控平台V8-2','阅读器服务版对接文档','泰山版(1)','可信电子文件管理平台_使用手册','健康','慧正流程中台技术方案V8.1','计算机软件著作权登记申请表','0230322','副本功能点及任务拆分2023-02-21','办公厅文件（双层OFD）','鉴定文书-数字版','可信电子文件管理平台_测试报告','统一运维部署工具_测试报告'],
            type:['ofd','pdf','ofd','ofd','png','excel','doc','wps','jpg','pdf','ofd','ofd'],
            state:[0,0,1,1,0,1,1,1,0,1,0,1,1,1]
        }
    },
    methods:{
        show(list,dates){
            this.listData = []
            this.visible = true;
            for(let i = 0;i < list; i++){
                this.listData.push({
                    name:this.list[i],
                    suffix:this.type[i],
                    state:this.state[i],
                    updateDate:dates
                })
            }
        }
    }
}
</script>
<style lang="less" scoped>
/deep/.el-dialog{
  background: #1d1b1bbf;
  .el-dialog__title{
    color: #fff;
  }
}
/deep/.el-table{
  background: transparent;
  color: #fff;
  th , tr{
    color: #fff;
     background: transparent;
  }
  .el-table__row:hover{
    td {
      background: #66ceebbf;
    }
  }
}

</style>